import React from 'react'

import { withStyles, makeStyles } from '@material-ui/core/styles'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import MuiDialogTitle from '@material-ui/core/DialogTitle'
import Slide from '@material-ui/core/Slide'
import IconButton from '@material-ui/core/IconButton'
import CloseIcon from '@material-ui/icons/Close'
import Typography from '@material-ui/core/Typography'

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />
})

const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
})

export default function CustomDialog({
  title = null,
  content = null,
  actions = false,
  fullWidth = false,
  maxWidth = 'sm',
  openDialog = false,
  handleClose = () => {
    return false
  },
  classesObj = false,
  hideCloseButton = false,
}) {
  const useStyles = makeStyles({
    rootDialogActions: classesObj?.dialogActions?.root ? classesObj?.dialogActions?.root : {},
  })

  const classes = useStyles()

  const DialogTitle = withStyles(styles)((props) => {
    const { children, classes, onClose, ...other } = props
    return (
      <MuiDialogTitle disableTypography className={classes.root} {...other}>
        <Typography variant="h6">{children}</Typography>
        {onClose && !hideCloseButton ? (
          <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
            <CloseIcon />
          </IconButton>
        ) : null}
      </MuiDialogTitle>
    )
  })

  return (
    <>
      <Dialog
        open={openDialog}
        TransitionComponent={Transition}
        keepMounted
        fullWidth={fullWidth}
        maxWidth={maxWidth}
        onClose={handleClose}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle id="customized-dialog-title" onClose={handleClose}>
          {title}
        </DialogTitle>
        <DialogContent>
          <DialogContentText>{content}</DialogContentText>
        </DialogContent>
        <DialogActions classes={classesObj?.dialogActions?.root ? { root: classes.rootDialogActions } : ''}>
          {actions &&
            Object.keys(actions).map((action) => (
              <Button
                onClick={actions[action]?.onClick}
                variant={actions[action]?.variant ?? 'text'}
                color={actions[action]?.color}
              >
                {actions[action]?.title}
              </Button>
            ))}
        </DialogActions>
      </Dialog>
    </>
  )
}
